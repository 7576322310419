import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import NewsletterBGImage from 'public/images/backgrounds/newsletter-popup-bg-image.webp';
import { Button } from '../ui/button';
import { ConsentStore } from 'src/stores/ConsentStore';
import { observer } from 'mobx-react';

const NewsletterPopup = observer(() => {
  const [open, setOpen] = React.useState<boolean>(false);

  const userInteractionCompleted = ConsentStore.isUserActionCompleted;
  const hasConsentSuccessfullyLoaded =
    ConsentStore.hasConsentSuccessfullyLoaded;

  React.useEffect(() => {
    const isSignUpCompleted = localStorage.getItem('bhamSignUp');

    if (!isSignUpCompleted) {
      let consentCheckTimer: NodeJS.Timeout;
      let interactionTimer: NodeJS.Timeout;

      consentCheckTimer = setTimeout(() => {
        if (!ConsentStore.hasConsentSuccessfullyLoaded) {
          setOpen(true);
        }
      }, 5000);

      if (userInteractionCompleted) {
        interactionTimer = setTimeout(() => {
          if (!isSignUpCompleted) {
            setOpen(true);
          }
        }, 2000);
      }

      return () => {
        clearTimeout(consentCheckTimer);
        clearTimeout(interactionTimer);
      };
    }
  }, [userInteractionCompleted, hasConsentSuccessfullyLoaded]);

  const handleSignUp = () => {
    window.location.href =
      'https://survey.updates.clays.bar/home/index?id=2sYOpzYVYqiYDnD1gy2dbA==&NewSession=1';
    localStorage.setItem('bhamSignUp', 'true');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        if (open) {
          setOpen(false);

          localStorage.setItem('bhamSignUp', 'false');
        }
      }}
    >
      <DialogContent
        className="flex flex-col justify-end items-center gap-8 h-[600px] md:h-[650px] w-full md:w-[600px] bg-transparent border-none text-white pb-14"
        style={{
          backgroundImage: `url(${NewsletterBGImage.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <DialogHeader className="flex flex-col text-white">
          <DialogTitle className="text-center text-4xl md:text-6xl">
            Get ready Birmingham!
          </DialogTitle>
          <DialogDescription className="text-center text-sm md:text-md text-white/70">
            Sign up to our mailing list and be the first to know our opening
            date, special events, and exclusive offers.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button
            className="bg-dark-green text-white rounded-full h-10 w-24 md:w-32 text-sm md:text-md"
            onClick={handleSignUp}
          >
            Sign up
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

export default NewsletterPopup;
